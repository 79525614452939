<template>
  <div class="about">
    <h1>This is an about page</h1>
    <Shapechanger style="width:50%;"
      original="/assets/img/mira.png"
      interim="/assets/img/dopplemira.png"
      shifted="/assets/img/nix.png"
      />
  </div>
</template>

<script>
import Shapechanger from "@/components/Shapechanger.vue";
export default {
  components: {
  Shapechanger
  }
}

</script>