<template>
  <p
  v-bind:class="free? 'strikethrough' : '' "
  >{{date}}: {{$helpers.formatNumber(cost)}} {{color}} {{breed}} {{form}}<span v-if="client">, for {{client}}, {{occupation}}</span></p>
</template>


<script>
export default {
  name: 'LedgerEntry',
  props: {
    date: { type: String, default: "Spring 5713"},
    client: { type: String, default: ""},
    occupation: { type: String, default: ""},
    cost: { type: Number, default: 0},
    breed: { type: String, default: "Magnolias"},
    color: { type: String, default: "Yellow"},
    form: { type: String, default: "blossoms"},
    free: { type: Boolean, default: false}
  },
}
</script>