<template>
  <transition name="fade">
    <div @mouseover="shift" @mouseleave="reset" class="outer">
        <!--could do as an array-->
        <img :src="shifted" id="2">
        <img :src="interim" id="1">
        <img :src="original" id="0">
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    original: { type: String, default: ""},
    interim: { type: String, default: ""},
    shifted: { type: String, default: ""}
  },
  data() {
    return {
      current: { type: String, default: this.original},
    }
  },

  methods: {
    shift(){
      setTimeout(
      function() {
        document.getElementById('0').classList.add("invis");
      }, 50);
      setTimeout(
      function() {
        document.getElementById('1').classList.add("invis");
      }, 500);

    },
    reset(){
      setTimeout(
      function() {
        document.getElementById('1').classList.remove("invis");
      }, 50);
      setTimeout(
      function() {
        document.getElementById('0').classList.remove("invis");
      }, 1000);

    }
  }
}
</script>

<style scoped>
.outer{
  position: relative;
}
img{
  position: absolute;
  transition: 1s;
}
.invis{
  opacity: 0;
}
</style>