<template>
  <div id="app">
    <b-navbar>
      <b-nav-item to="/">Home</b-nav-item>
      <b-nav-item to="/brasspire">Brasspire</b-nav-item>
      <!--<b-nav-item to="/about">The Crawl</b-nav-item>
      <div class="navbar-item">
        <div class="buttons">
          <div v-if="!$auth.loading">
            <a v-if="!$auth.isAuthenticated" @click="login" class="button is-dark"><strong>Sign in</strong></a>
            <a v-if="$auth.isAuthenticated" @click="logout" class="button is-dark"><strong>Log out</strong></a>
          </div>
        </div>
      </div>-->
    </b-navbar>
    <img alt="" class="bg" v-bind:src="bg">
    <div class="router-wrapper">
      <router-view/>
    </div>
  </div>
</template>


<script>

export default {
  props: {
    bg: {type: String, default: "/assets/img/default-bg.jpg"}
  },
  methods: {
     login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}

}

</script>